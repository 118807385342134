import { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { Helmet } from 'react-helmet';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { parseDataSet, ReligantGraph } from "../components/Charts";
import { theme } from "../theme";
import { Logo } from "../components/Logo";
import radixLogo from "../assets/radix-logo.png";
import styled from "styled-components";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Religant Aggregate Feed",
    },
    decimation: {
      enabled: true,
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          return context.raw.toString();
        },
      },
    },
  },
};

const Dashboard = () => {
  const [socketUrl, setSocketUrl] = useState("wss://ws.religantoracles.com");

  const [dataSet, setDataSet] = useState([] as Array<{ x: Date; y: number }>);

  const { lastMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    console.log("lastMessage", lastMessage);
    if (lastMessage !== null) {
    setDataSet(() => parseDataSet(lastMessage!));
    }
  }, [lastMessage, setDataSet]);

  return (
    <DashboardContainer
      style={{
        backgroundColor: theme.colors.background,
        backgroundImage: theme.backgroundImage,
        color: theme.colors.text.title,
      }}
    >
      <Helmet>
        <title>Dashboard | Religant</title>
      </Helmet>
      <Header>
        <LogoLink to="/">
          <Logo textColor={theme.colors.text.logo.light} />
        </LogoLink>
        <TitleContainer>
          <CoinLogo src={radixLogo} alt="Radix logo" height="16px" />
          <h1>XRD to USD</h1>
        </TitleContainer>
        <Spacer></Spacer>
      </Header>
      <ReligantGraph
        gridColor={theme.colors.chart.grid}
        lineColor={theme.colors.chart.line}
        ticksColor={theme.colors.chart.labels}
        rawData={dataSet}
      />
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-content: center;
  padding: 2rem;
  min-height: 100vh;
  box-sizing: border-box;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const Spacer = styled.div`
  width: 200px;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CoinLogo = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

export default Dashboard;
