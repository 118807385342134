type Theme = {
  backgroundImage?: string;
  colors: {
    background?: string;
    text: {
      logo: {
        light: string;
        dark: string;
      },
      title: string;
      body: {
        light: string;
        dark: string;
      }
    },
    buttons: {
      primary: {
        text: string;
        background: string;
        backgroundHover: string;
      },
      secondary: {
        text: string;
        background: string;
        backgroundHover: string;
      },
    }
    chart: {
      grid: string;
      labels: string;
      line: string | string[];
    };
  };
};

/** Blue theme */
export const theme: Theme = {
  backgroundImage: "linear-gradient(rgb(0, 29, 80) 50%, rgb(0, 45, 105) 100%)",
  colors: {
    text: {
      logo: {
        light: "#e8e9f5",
        dark: "#005888",
      },
      title: "#ffffff",
      body: {
        light: "#ffffff",
        dark: "#2b2b2b",
      }
    },
    buttons: {
      primary: {
        text: "#ffffff",
        background: "linear-gradient(25deg, #FF52AB 0%, #fbaa25 80%)",
        backgroundHover: "linear-gradient(205deg, #FF52AB 0%, #fbaa25 80%)",
      },
      secondary: {
        text: "#2b2b2b",
        background: "#e8e9f5",
        backgroundHover: "#dbdbe9",
      },
    },
    chart: {
      grid: "#1D4EA9",
      labels: "#ffffff",
      line: ["#FF52AB", "#fbaa25"],
    },
  },
};

/** White and orange theme */
// export const theme: Theme = {
//   colors: {
//     background: "#ffffff",
//     logoText: "#red",
//     titleText: "#555555",
//     chart: {
//       grid: "#dddddd",
//       labels: "#777777",
//       line: ["#ff6384", "#fbaa25"],
//     },
//   },
// };
