import { Link } from "react-router-dom";
import { Logo } from "../components/Logo";
import { theme } from "../theme";
import styled, { css } from "styled-components";

export const Landing = () => {
  return (
    <LandingContainer>
      <Header>
        <Logo textColor={theme.colors.text.logo.dark} scale={2} />
        <LandingTitle>Radix Native Oracle</LandingTitle>
      </Header>
      <Main>
        <DocsLink href="https://github.com/mlabs-haskell/religant-integration-example" target="_blank">
          Integration Documentation (Mainnet)
        </DocsLink>
        <DocsLink href="https://github.com/mlabs-haskell/religant-integration-example/tree/stokenet" target="_blank">
          Integration Documentation (Stokenet)
        </DocsLink>
        <DashboardLink to="/dashboard">Live Oracle Dashboard</DashboardLink>
      </Main>
    </LandingContainer>
  );
};

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
  box-sizing: border-box;
  gap: 1.5rem;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LandingTitle = styled.h1`
  text-align: center;
  font-size: 6rem;
  font-weight: 300;
  margin: 20px;

  @media (max-width: 576px) {
    font-size: 3.5rem;
  }
`;

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const LinkStyle = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 300px;
  height: 60px;
  text-decoration: none;
  border-radius: 30px;
  padding: 10px;
  margin: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease-in-out;
`;

const DocsLink = styled.a`
  ${LinkStyle}
  color: ${theme.colors.buttons.secondary.text};
  background-color: ${theme.colors.buttons.secondary.background};
  
  &:hover {
    background-color: ${theme.colors.buttons.secondary.backgroundHover};
  }
`;

const DashboardLink = styled(Link)`
  ${LinkStyle}
  color: ${theme.colors.buttons.primary.text};
  background-image: ${theme.colors.buttons.primary.background};

  &:hover {
    background-image: ${theme.colors.buttons.primary.backgroundHover};
  }
`;
