import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { TooltipItem, ChartOptions } from "chart.js";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import ZoomPlugin from "chartjs-plugin-zoom";
import styled from "styled-components";

type PriceDataDB = {
  id: number;
  price: number;
  timestamp: Date;
};

ChartJS.register(
  CategoryScale,
  TimeSeriesScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ZoomPlugin,
  Filler
);

// const canvasBG = {
//     id: 'customCanvasBackgroundColor',
//     beforeDraw: (chart: any, args: any, options: any) => {
//         const { ctx } = chart;
//         ctx.save();
//         ctx.globalCompositeOperation = 'destination-over';
//         ctx.fillStyle = options.color || '#99ffff';
//         ctx.fillRect(0, 0, chart.width, chart.height);
//         ctx.restore();
//     }
// };

const compItems = (
  itemA: { x: Date; y: number },
  itemB: { x: Date; y: number }
) => {
  if (itemA.x < itemB.x) {
    return -1;
  } else if (itemA.x > itemB.x) {
    return 1;
  } else {
    return 0;
  }
};

let width: number, height: number, gradient: any;
function getGradient(ctx: any, chartArea: any, colors: string[]) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

    colors.forEach((color, index) => {
      gradient.addColorStop(index / (colors.length - 1), color);
    });
  }

  return gradient;
}

export const parseDataSet = (
  msg: MessageEvent<any>
): Array<{ x: Date; y: number }> => {
  console.log(JSON.stringify(msg));
  const data = JSON.parse(msg.data);
  if (Array.isArray(data)) {
    const result = data
      .map((item_) => {
        const item: PriceDataDB = item_ as PriceDataDB;
        return { x: new Date(item.timestamp), y: item.price };
      })
      .sort(compItems);

    console.log(JSON.stringify(result));
    return result;
  } else {
    throw new Error("Not an array" + JSON.stringify(msg.data));
  }
};

type ReligantGraphProps = {
  gridColor: string;
  ticksColor: string;
  lineColor: string | string[];
  rawData: Array<{ x: Date; y: number }>;
};

export const ReligantGraph = ({
  gridColor,
  ticksColor,
  lineColor,
  rawData,
}: ReligantGraphProps) => {
  // Each date displayed on the graph is tracked to ensure dates are not repeated.
  // Using a mutable variable here is a hack to avoid re-rendering the entire graph.
  // `useRef` was tried but was causing all dates to be skipped on initial load.
  const displayedDates = new Set();

  const options: ChartOptions<"line"> = {
    responsive: true,
    onResize: (e: any) => {
      displayedDates.clear();
    },
    resizeDelay: 100,
    scales: {
      y: {
        ticks: {
          color: ticksColor,
          font: {
            size: 16,
          },
        },
        grid: {
          color: gridColor,
          lineWidth: 0.5,
        },
        // scaleLabel: {
        //   display: false,
        // },
      },
      x: {
        type: "timeseries",
        time: {
          unit: "day",
          displayFormats: {
            day: "MMM d",
          },
        },
        ticks: {
          source: "data",
          color: ticksColor,
          font: {
            size: 16,
          },
          autoSkip: true,
          autoSkipPadding: 75,
          maxRotation: 0,
          callback: (value) => {
            if (typeof value !== "number") return null;

            const date = format(new Date(value), "MMM dd");
            if (displayedDates.has(date)) {
              return null;
            } else {
              displayedDates.add(date);
              return date;
            }
          },
        },
        border: {
          display: true,
        },
        grid: {
          color: gridColor,
          lineWidth: 0.5,
        },
      },
    },
    elements: {
      point: {
        radius: 8,
      },
      line: {
        borderWidth: 2.5,
      },
    },
    interaction: {
      intersect: false,
      mode: "point",
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: gridColor,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (e: any) => {
            return `${e.dataset.label}: ${e.raw.y.toString()}`;
          },
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
          onZoom: (e: any) => {
            displayedDates.clear();
          },
        },
        pan: {
          enabled: true,
          onPan: (e: any) => {  
            displayedDates.clear();
          },
        }
      },
    },
  };

  return (
    <ChartContainer>
      <Line
        options={options}
        data={{
          datasets: [
            {
              label: "USD Price",
              data: rawData.map((d) => ({ x: d.x, y: d.y })),
              fill: false,
              pointStyle: false,
              borderColor: (context: any) => {
                if (typeof lineColor === 'string') {
                  return lineColor;
                }

                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  // This case happens on initial chart load
                  return null;
                }
                return getGradient(ctx, chartArea, lineColor);
              },
            },
          ],
        }}
      />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  margin-top: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 160px);

  @media (max-width: 576px) {
    width: calc(100vw - 2rem);
  }
`;

