import styled from "styled-components";

type LogoProps = {
  textColor: string;
  scale?: number;
};

export const Logo = ({ textColor, scale = 1 }: LogoProps) => {
  return (
    <LogoContainer>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 77.73 77.73"
        width={`${scale * 38}px`}
        height={`${scale * 38}px`}
      >
        <defs>
          <style>{`
      .cls-1 {
        fill: #6da6cc;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #231f20;
      }

      .cls-3 {
        fill: #e8e9f5;
      }

      .cls-4 {
        fill: #fbaa19;
      }

      .cls-5 {
        fill: #003953;
      }

      .cls-6 {
        fill: #005888;
      }
    `}</style>
        </defs>
        <circle
          className="cls-2"
          cx="58.84"
          cy="46.66"
          r="4.37"
          transform="translate(-15.76 55.27) rotate(-45)"
        />
        <path
          className="cls-5"
          d="m60.93,70.86c-6.27,4.33-13.87,6.87-22.06,6.87-17.22,0-31.82-11.19-36.92-26.7L25.92,18.38c-1.61,2.44-2.55,5.37-2.55,8.51,0,4.4,1.83,8.37,4.78,11.19l32.78,32.78Z"
        />
        <path
          className="cls-3"
          d="m38.86,0C17.4,0,0,17.4,0,38.86c0,4.25.68,8.34,1.94,12.17L25.92,18.38c.3-.47.63-.92.99-1.35.44-.53.91-1.03,1.42-1.5,2.76-2.57,6.47-4.13,10.53-4.13,8.56,0,15.49,6.94,15.49,15.49,0,4.07-1.56,7.76-4.13,10.53-2.82,3.05-6.87,4.97-11.36,4.97-4.1,0-7.83-1.59-10.6-4.19l32.66,32.66c10.15-7.01,16.8-18.72,16.8-31.99C77.73,17.4,60.33,0,38.86,0Zm19.97,51.03c-2.41,0-4.37-1.96-4.37-4.37s1.96-4.37,4.37-4.37,4.37,1.96,4.37,4.37-1.96,4.37-4.37,4.37Z"
        />
        <circle
          className="cls-4"
          cx="58.84"
          cy="46.66"
          r="4.37"
          transform="translate(-15.76 55.27) rotate(-45)"
        />
        <path
          className="cls-6"
          d="m50.23,37.42c-2.82,3.05-6.87,4.97-11.36,4.97-4.1,0-7.83-1.59-10.6-4.19l-.12-.12c-2.95-2.82-4.78-6.79-4.78-11.19,0-3.14.94-6.07,2.55-8.51l.99-1.35c.44-.53.91-1.03,1.42-1.5l21.89,21.89Z"
        />
        <path
          className="cls-1"
          d="m54.36,26.89c0,4.07-1.56,7.76-4.13,10.53L28.33,15.53c2.76-2.57,6.47-4.13,10.53-4.13,8.56,0,15.49,6.94,15.49,15.49Z"
        />
      </svg>
      <svg
        fill={textColor}
        viewBox="0 0 322 45.29"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        height={`${scale * 16}px`}
      >
        <g id="Layer_1">
          <title>Layer 1</title>
          <g id="svg_25">
            <path
              id="svg_1"
              strokeWidth="0px"
              d="m1.30501,44.125l0,-42.95l22.12,0c4.1,0 7.24,0.35 9.4,1.05s3.92,2.01 5.24,3.91c1.33,1.9 1.99,4.22 1.99,6.96c0,2.38 -0.51,4.44 -1.52,6.17s-2.41,3.13 -4.19,4.2c-1.13,0.68 -2.69,1.25 -4.66,1.7c1.58,0.53 2.73,1.06 3.45,1.58c0.49,0.35 1.2,1.1 2.12,2.26c0.93,1.15 1.55,2.04 1.86,2.67l6.43,12.45l-8.18,0c-4.2,0 -8.06,-2.3 -10.06,-6l-3.85,-7.13c-0.9,-1.7 -1.7,-2.8 -2.4,-3.31c-0.96,-0.66 -2.04,-1 -3.25,-1l-1.17,0l0,17.43l-13.33,0l0,0.01zm13.33,-25.55l5.6,0c0.6,0 1.78,-0.2 3.52,-0.59c0.88,-0.18 1.6,-0.62 2.15,-1.35s0.83,-1.55 0.83,-2.49c0,-1.39 -0.44,-2.45 -1.32,-3.19s-2.53,-1.11 -4.95,-1.11l0,0c-3.22,0 -5.83,2.61 -5.83,5.83l0,2.9z"
            />
            <path
              id="svg_2"
              strokeWidth="0px"
              d="m47.76501,1.175l35.57,0l0,9.17l-22.27,0l0,6.83l20.65,0l0,8.76l-20.65,0l0,8.47l22.91,0c0,5.37 -4.35,9.73 -9.73,9.73l-26.48,0l0,-42.95l0,-0.01z"
            />
            <path
              id="svg_3"
              strokeWidth="0px"
              d="m91.12501,1.175l13.27,0l0,32.37l10.14,0c5.84,0 10.58,4.74 10.58,10.58l0,0s-33.98,0 -33.98,0l0,-42.95l-0.01,0z"
            />
            <path
              id="svg_4"
              strokeWidth="0px"
              d="m125.02501,1.175l13.3,0l0,42.95l0,0c-7.35,0 -13.3,-5.95 -13.3,-13.3l0,-29.65z"
            />
            <path
              id="svg_5"
              strokeWidth="0px"
              d="m168.70501,28.565l0,-8.94l20.51,0l0,9.47c0,6.52 -4.21,12.3 -10.42,14.31l0,0c-3.02,0.97 -6.6,1.45 -10.74,1.45c-5.1,0 -9.25,-0.87 -12.47,-2.61c-3.21,-1.74 -5.7,-4.33 -7.47,-7.76c-1.77,-3.44 -2.65,-7.38 -2.65,-11.84c0,-4.69 0.97,-8.76 2.9,-12.23s4.77,-6.1 8.5,-7.9c2.91,-1.39 6.83,-2.08 11.75,-2.08s8.3,0.43 10.65,1.29c2.35,0.86 4.31,2.19 5.86,4s2.72,4.1 3.5,6.87l-12.8,2.29c-0.53,-1.62 -1.42,-2.86 -2.68,-3.72s-2.87,-1.29 -4.82,-1.29c-2.91,0 -5.23,1.01 -6.96,3.03s-2.59,5.22 -2.59,9.59c0,4.65 0.87,7.97 2.62,9.96c1.75,1.99 4.18,2.99 7.31,2.99c1.48,0 2.9,-0.21 4.25,-0.64c0.08,-0.03 0.17,-0.05 0.25,-0.08c2.62,-0.89 4.38,-3.38 4.38,-6.16l0,0s-8.88,0 -8.88,0z"
            />
            <path
              id="svg_6"
              strokeWidth="0px"
              d="m222.34501,37.035l-15.07,0l-2.09,7.09l0,0c-6.55,0 -11.09,-6.55 -8.78,-12.68l11.38,-30.26l14.48,0l16.14,42.95l-13.9,0l-2.15,-7.09l-0.01,-0.01zm-2.75,-9.29l-4.74,-15.44l-4.69,15.44l9.43,0z"
            />
            <path
              id="svg_7"
              strokeWidth="0px"
              d="m241.37501,1.175l12.39,0l16.17,23.76l0,-23.76l12.51,0l0,42.95l-12.51,0l-16.08,-23.58l0,23.58l0,0c-6.89,0 -12.48,-5.59 -12.48,-12.48l0,-30.47z"
            />
            <path
              id="svg_8"
              strokeWidth="0px"
              d="m280.35501,1.175l40.34,0l0,10.61l-13.54,0l0,32.34l0,0c-7.33,0 -13.27,-5.94 -13.27,-13.27l0,-19.07l-13.54,0l0,-10.61l0.01,0z"
            />
          </g>
        </g>
      </svg>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
